import {currentLanguage, translate} from "../../TranslatePipe";
import {Company} from "./ExperienceList";
import {Technology} from "../Technology";

export function Experience(props: { company: Company }) {
    const company = props.company;
    const responsibilities = translate('experience.' + company.id + '.responsibilities');

    return <article key={company.id} id={company.id}>
        <div className={'experience'}>
            <h3>{translate('experience.' + company.id + '.title')} – {translate('experience.' + company.id + '.content')}</h3>
            <div className={'dot'}/>

            <span className={'time'}>
                            {company.getDate()}
            </span>
        </div>
        <div className={'experience'}>
            {props.company.stack?.map(t => <Technology key={t} type={t}/>)}
        </div>
        <ul className={'show-on-print'}>
            {
                Array.isArray(responsibilities) ?
                    responsibilities?.map(
                        (responsibility: string, i: number) =>
                            <li key={i}>{responsibility}</li>
                    ) :
                    <></>
            }
        </ul>
    </article>
}

/**
 * This file will be generated by Typed Translator
 */
type LanguageId = string;
type TranslationKey = string;
const translationBundle = new Map<LanguageId, Map<TranslationKey, any>>();
translationBundle.set('pl', new Map());
translationBundle.set('en', new Map());
let currentLang = translationBundle.get('pl');
currentLang!.set('month.1', 'styczeń');
currentLang!.set('month.2', 'luty');
currentLang!.set('month.3', 'marzec');
currentLang!.set('month.4', 'kwiecień');
currentLang!.set('month.5', 'maj');
currentLang!.set('month.6', 'czerwiec');
currentLang!.set('month.7', 'lipiec');
currentLang!.set('month.8', 'sierpień');
currentLang!.set('month.9', 'wrzesień');
currentLang!.set('month.10', 'październik');
currentLang!.set('month.11', 'listopad');
currentLang!.set('month.12', 'grudzień');
currentLang!.set('common.present', 'aktualnie');
currentLang!.set('common.RODO', 'Wyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb niezbędnych do realizacji procesu rekrutacji (zgodnie z ustawą z dnia 10 maja 2018 roku o ochronie danych osobowych (Dz. Ustaw z 2018, poz. 1000) oraz zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO).');
currentLang!.set('common.click-on-element', 'Każdą technologię opatrzyłem subiektywną oceną znajomości, biorąc pod uwagę różną złożoność technologii. Przykładowo Angular – pomimo codziennej pracy w tym frameworku mam jeszcze sporo do nauki. Dołączam linki do gita, aby łatwiej zobrazować jak wygląda mój kod.');

currentLang!.set('common.skills', 'umiejętności');

currentLang!.set('common.contact', 'dane kontaktowe');
currentLang!.set('common.languages', 'języki');


currentLang!.set('education.title', 'wykształcenie');
currentLang!.set('experience.mgr.title', 'Politechnika Wrocławska');
currentLang!.set('experience.mgr.content', 'Magister informatyki – specjalność Systemy Informacyjne');
currentLang!.set('experience.mgr.responsibilities', [])


currentLang!.set('experience.engineer.title', 'Zachodniopomorski Uniwersytet Technologiczny');
currentLang!.set('experience.engineer.content', 'Inżynier teleinformatyki');
currentLang!.set('experience.engineer.responsibilities', [])


currentLang!.set('experience.title', 'doświadczenie');

currentLang!.set('experience.spyrosoft.title', 'Spyrosoft');
currentLang!.set('experience.spyrosoft.content', 'Frontend developer');
currentLang!.set('experience.spyrosoft.responsibilities', [
    'Projekt w Angular',
    'Tworzenie kodu wysokiej jakości',
    'Przeprowadzanie Code review',
    'Branie udziału w backlog refinement oraz szacowaniu (Scrum)',
    'Prezentowanie zmian na spotkaniach Demo',
]);

currentLang!.set('experience.teleste.title', 'Teleste');
currentLang!.set('experience.teleste.content', 'Frontend developer');
currentLang!.set('experience.teleste.responsibilities', [
    'Projekt w Angular',
    'Integracja systemu legacy napisanego w AngularJS i JQuery',
    'Tworzenie kodu wysokiej jakości',
    'Przeprowadzanie Code review',
    'Branie udziału w backlog refinement oraz szacowaniu (Scrum)',
    'Prezentowanie zmian na spotkaniach Demo',
]);

currentLang!.set('experience.unit4.title', 'Unit4');
currentLang!.set('experience.unit4.content', 'Frontend developer');
currentLang!.set('experience.unit4.responsibilities', [
    'Projekt w Angular. Okacjonalnie zadania backendowe w Javie',
    'Integracja systemu legacy napisanego w AngularJS i VanillaJS',
    'Postępowanie zgodne ze standardami i najlepszymi praktykami',
    'Wdrażanie techniki Test Driven Development',
    'Branie aktywnego udziału w codziennych spotkaniach dotyczących postępu oraz planowania iteracji (bazując na SCRUM)',
]);

currentLang!.set('experience.konsolamini.title', 'Konsolamini');
currentLang!.set('experience.konsolamini.content', 'W marcu 2019 założyłem działalność gospodarczą.');
currentLang!.set('experience.konsolamini.responsibilities', [
    'Rozpocząłem własną działalność gospodarczą. Od tego czasu pracuję głównie w formie B2B'

]);


currentLang!.set('experience.bv.title', 'blue veery');
currentLang!.set('experience.bv.content', 'Full-stack developer');
currentLang!.set('experience.bv.responsibilities', [
    'Projekty tworzone w Angular (4-8), React Native i Spring',
    'Programowanie front-end oraz back-end',
    'Branie udziału w planowaniu sprintów i retrospektywach',
    'W razie konieczności nauka i wykonywanie projektów w językach PHP i Java',
])

currentLang!.set('common.birth', 'Data urodzenia');
currentLang!.set('lang.pl', 'Polski');
currentLang!.set('lang.en', 'Angielski');

//===================================== EN

currentLang = translationBundle.get('en');
currentLang!.set('month.1', 'January');
currentLang!.set('month.2', 'February');
currentLang!.set('month.3', 'March');
currentLang!.set('month.4', 'April');
currentLang!.set('month.5', 'May');
currentLang!.set('month.6', 'June');
currentLang!.set('month.7', 'July');
currentLang!.set('month.8', 'August');
currentLang!.set('month.9', 'September');
currentLang!.set('month.10', 'October');
currentLang!.set('month.11', 'November');
currentLang!.set('month.12', 'December');
currentLang!.set('common.present', 'currently');

currentLang!.set('common.RODO', 'I hereby give consent for my personal data included in my application to be processed for the purposes of the recruitment process under the European Parliament\'s and Council of the European Union Regulation on the Protection of Natural Persons as of 27 April 2016, with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (Data Protection Directive)');
currentLang!.set('common.click-on-element', 'Each technology has a subjective assessment of knowledge, taking into account the different complexity of the technology. For example, Angular - despite my daily work in this framework, I still have a lot to learn. I attach links to git, to help you visualize what my code looks like. ');

currentLang!.set('common.skills', 'skills');

currentLang!.set('common.contact', 'contact details');
currentLang!.set('common.languages', 'languages');


currentLang!.set('education.title', 'education');
currentLang!.set('experience.mgr.title', 'Wrocław University of Technology');
currentLang!.set('experience.mgr.content', 'Master in Computer Science - specializing in Information Systems');
currentLang!.set('experience.mgr.responsibilities', [])


currentLang!.set('experience.engineer.title', 'West Pomeranian University of Technology');
currentLang!.set('experience.engineer.content', 'Information and Communication Technologies engineer');
currentLang!.set('experience.engineer.responsibilities', [])

currentLang!.set('experience.title', 'experience');

currentLang!.set('experience.spyrosoft.title', 'Spyrosoft');
currentLang!.set('experience.spyrosoft.content', 'Frontend developer');
currentLang!.set('experience.spyrosoft.responsibilities', [
    'Project in Angular',
    'Creating high quality code',
    'Reviewing the code',
    'Participation in the redefinition and estimation of the backlog (Scrum)',
    'Presenting changes at Demo meetings'
]);

currentLang!.set('experience.teleste.title', 'Teleste');
currentLang!.set('experience.teleste.content', 'Frontend developer');
currentLang!.set('experience.teleste.responsibilities', [
    'Project in Angular',
    'Integration with legacy system written in AngularJS and in JQuery',
    'Developing high quality code',
    'Doing Code review',
    'Taking part in backlog refinement and estimation (with Scrum methodology)',
    'Presenting changes at Demo meetings'
]);

currentLang!.set('experience.unit4.title', 'Unit4');
currentLang!.set('experience.unit4.content', 'Frontend developer');
currentLang!.set('experience.unit4.responsibilities', [
    'Angular project. Occasionally Java tasks',
    'Integration with legacy system written in AngularJS',
    'Following the best practices of Test Driven Development',
    'Taking an active part in daily progress and iteration planning meetings (based on Scrum)'
]);

currentLang!.set('experience.konsolamini.title', 'Konsolamini');
currentLang!.set('experience.konsolamini.content', 'Owner');
currentLang!.set('experience.konsolamini.responsibilities', [
    'I started running a business. Since then, I can have B2B contracts'
]);


currentLang!.set('experience.bv.title', 'blue veery');
currentLang!.set('experience.bv.content', 'Full-stack developer');
currentLang!.set('experience.bv.responsibilities', [
    'Front-end (Angular) and back-end (Spring) programming',
    'Taking part in sprint planning (Extreme Programing)',
    'Learning and implementing projects in PHP and Java languages',
])

currentLang!.set('common.birth', 'Date of birth');

currentLang!.set('lang.pl', 'Polish');
currentLang!.set('lang.en', 'English');

export default translationBundle;
